async function postData(url, data, csrftoken) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

import Cookies from 'js-cookie';

window.contactViewHandler = (isNewSection = false) => {
  function validateEmail(email) {
    // eslint-disable-next-line no-control-regex
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email,
    );
  }

  function contactFormEvents() {
    let contact = document.getElementsByClassName('contact-send-new');
    if (contact.length > 0) {
      const form = document.querySelector('.jsContactForm'),
        formEmail = form.querySelector('.jsFormEmail'),
        formDesc = form.querySelector('.jsFormDesc'),
        formPrivacy = form.querySelector('.jsFormPriv'),
        formFields = form.querySelectorAll('.jsFormField'),
        formRodo = form.querySelector('.jsFormRodo'),
        formSubmit = form.querySelector('.jsFormSubmit'),
        contactContainer = document.getElementsByClassName('jsContact'),
        formContainer = document.getElementsByClassName('jsContactFormContainer'),
        feedbackContainer = document.getElementsByClassName('jsContactFeedbackContainer'),
        formRodoContainer = form.querySelector('.jsContactRodoContainer');

      // live form fields validation
      formFields.forEach(field => {
        field.addEventListener('input', () => {
          field.value === '' ? field.classList.add('error') : field.classList.remove('error');

          if (field.type === 'email' && validateEmail(field.value)) {
            field.classList.remove('error');
          } else if (field.type === 'email' && !validateEmail(field.value)) {
            field.classList.add('error');
          }
        });
      });

      formRodo.addEventListener('click', function () {
        this.value = this.checked ? 'checked' : 'unchecked';
        if (this.checked && isNewSection) {
          formRodo.nextElementSibling.classList.remove('error');
        }
      });
      formSubmit.addEventListener('click', e => {
        e.preventDefault();
        document.querySelector('#contact-form-submit-btn').style.pointerEvents = 'none';
        const email = formEmail.value,
          description = formDesc.value,
          privacy = formPrivacy.value,
          csrftoken = Cookies.get('csrftoken');
        let isFormCorrect = true;

        // form field validation
        formFields.forEach(field => {
          if (field.value === '') {
            field.classList.add('error');
            isFormCorrect = false;
          } else {
            field.classList.remove('error');
          }

          if (field.type === 'email' && !validateEmail(field.value)) {
            field.classList.add('error');
            isFormCorrect = false;
          }
        });

        if (formRodo.value === 'unchecked') {
          isFormCorrect = false;
          formRodo.nextElementSibling.classList.add('error');
        }

        if (isFormCorrect) {
          try {
            formSubmit.setAttribute('disabled', true);
            const response = postData(
              '/api/contact-form/',
              {
                email: email,
                description: description,
                privacy: privacy,
              },
              csrftoken,
            ).then(data => {
              gtag('event', 'sendForm', {
                event_category: 'contactForm',
                event_label: 'form',
                value: '1',
              });

              if (isNewSection) {
                formSubmit.innerText = 'Form sent successfully';
                formEmail.disabled = true;
                formDesc.disabled = true;
              } else {
                formEmail.value = '';
                formDesc.value = '';
                formSubmit.removeAttribute('disabled');
                window.scrollTo({ top: 0, behavior: 'smooth' });
                document.querySelector('#jsContactFeedbackContainer').style.display = 'block';
              }
            });
          } catch (error) {
            //console.error(`Not send ${error.message}`);
            formSubmit.removeAttribute('disabled');
          }
        } else {
          document.querySelector('#contact-form-submit-btn').style.pointerEvents = 'auto';
        }
      });
    }
  }

  if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    contactFormEvents();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      contactFormEvents();
    });
  }
};

window.newsletterViewHandler = () => {
  function validateEmail(email) {
    // eslint-disable-next-line no-control-regex
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
      email,
    );
  }

  function newsletterFormEvents() {
    let contact = document.getElementsByClassName('newsletter-send-new');
    if (contact.length > 0) {
      const form = document.querySelector('.jsNewsletterForm'),
        formEmail = form.querySelector('.jsFormEmail'),
        formName = form.querySelector('.jsFormName'),
        formFields = form.querySelectorAll('.jsFormField'),
        formSubmit = form.querySelector('.jsFormSubmit'),
        contactContainer = document.getElementsByClassName('jsNewsletter');

      // live form fields validation
      formFields.forEach(field => {
        field.addEventListener('input', () => {
          field.value === '' ? field.classList.add('error') : field.classList.remove('error');

          if (field.type === 'email' && validateEmail(field.value)) {
            field.classList.remove('error');
          } else if (field.type === 'email' && !validateEmail(field.value)) {
            field.classList.add('error');
          }
        });
      });

      formSubmit.addEventListener('click', e => {
        e.preventDefault();
        document.querySelector('#newsletter-form-submit-btn').style.pointerEvents = 'none';

        const email = formEmail.value,
          name = formName.value,
          csrftoken = Cookies.get('csrftoken');
        let isFormCorrect = true;

        // form field validation
        formFields.forEach(field => {
          if (field.value === '') {
            field.classList.add('error');
            isFormCorrect = false;
          } else {
            field.classList.remove('error');
          }

          if (field.type === 'email' && !validateEmail(field.value)) {
            field.classList.add('error');
            isFormCorrect = false;
          }
        });

        if (isFormCorrect) {
          try {
            formSubmit.setAttribute('disabled', true);
            const response = postData(
              '/api/newsletter-form/',
              {
                name: name,
                email: email,
              },
              csrftoken,
            ).then(data => {
              formEmail.value = '';
              formDesc.value = '';
              formSubmit.removeAttribute('disabled');
              window.scrollTo({ top: 0, behavior: 'smooth' });
              document.querySelector('#jsContactFeedbackContainer').style.display = 'block';
              gtag('event', 'sendForm', {
                event_category: 'NewsletterForm',
                event_label: 'form',
                value: '1',
              });
            });
          } catch (error) {
            //console.error(`Not send ${error.message}`);
            formSubmit.removeAttribute('disabled');
          }
        } else {
          document.querySelector('#newsletter-form-submit-btn').style.pointerEvents = 'auto';
        }
      });
    }
  }

  if (document.readyState === 'complete' || (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
    newsletterFormEvents();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      newsletterFormEvents();
    });
  }
};
